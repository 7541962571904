var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.isDataLoaded)?_c('b-card',[_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]):_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"9","md":"8"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"name"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":("name." + k),"name":_vm.$t('general.field_lang', { field: _vm.$t('labels.name'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) }),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', { field: _vm.$t('labels.name'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('general.field_lang', { field: _vm.$t('labels.name'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })},model:{value:(_vm.form.name[k]),callback:function ($$v) {_vm.$set(_vm.form.name, k, $$v)},expression:"form.name[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"details"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":("details." + k),"name":_vm.$t('general.field_lang', { field: _vm.$t('labels.details'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) }),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', { field: _vm.$t('labels.details'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })}},[_c('quill-editor',{attrs:{"options":_vm.snowOption},model:{value:(_vm.form.details[k]),callback:function ($$v) {_vm.$set(_vm.form.details, k, $$v)},expression:"form.details[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"3","md":"4"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"country_id","name":_vm.$t('country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('country')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name_label","options":_vm.countries,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('country')},model:{value:(_vm.form.country_id),callback:function ($$v) {_vm.$set(_vm.form, "country_id", $$v)},expression:"form.country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"image","name":_vm.$t('labels.image'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImagePreview',{attrs:{"label":_vm.$t('labels.image')},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}})],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('labels.status'))+" ")]),_c('b-form-checkbox',{attrs:{"checked":"true","value":"active","unchecked-value":"inactive","name":"check-button","switch":""},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1)])],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12 text-center"}},[_c('LoadingButton'),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('labels.reset'))+" ")])],1)],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }