<template>
  <div>
    <validation-observer
      ref="form"
      v-slot="{invalid}"
    >
      <b-card v-if='!isDataLoaded'>
        <div class='text-center text-primary my-2'>
          <b-spinner class='align-middle' />
        </div>
      </b-card>

      <b-form
        novalidate
        @submit.prevent="onSubmit"
        v-else
      >

        <b-row>
          <b-col
            cols="12"
            xl="9"
            md="8"
          >
            <b-card>
              <b-row>
                <b-col cols="12">
                  <LocaleTabs
                    :errors="formErrors"
                    name="name"
                  >
                    <template
                      v-for="(i,k,idx) in languages"
                      :slot="`lang${idx}`"
                      slot-scope="slotScope"
                    >
                      <ValidationProvider
                        :key="idx"
                        v-slot="{ errors }"
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid="`name.${k}`"
                        :name="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        >
                          <b-form-input
                            v-model="form.name[k]"
                            :placeholder="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
                <b-col cols="12">
                  <LocaleTabs
                    :errors="formErrors"
                    name="details"
                  >
                    <template
                      v-for="(i,k,idx) in languages"
                      :slot="`lang${idx}`"
                      slot-scope="slotScope"
                    >
                      <ValidationProvider
                        :key="idx"
                        v-slot="{ errors }"
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid="`details.${k}`"
                        :name="$t('general.field_lang', { field: $t('labels.details'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('general.field_lang', { field: $t('labels.details'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        >
                          <quill-editor
                            v-model="form.details[k]"
                            :options="snowOption"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col
            cols="12"
            xl="3"
            md="4"
          >
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <validation-provider
                      v-slot="{ errors }"
                      vid="country_id"
                      :name="$t('country')"
                      rules="required"
                  >
                    <b-form-group
                        :label="$t('country')"
                    >
                      <v-select
                          v-model="form.country_id"
                          :dir="$store.state.appConfig.layout.direction"
                          label="name_label"
                          :options="countries"
                          :reduce="item => item.id"
                          :placeholder="$t('country')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    vid="image"
                    :name="$t('labels.image')"
                    rules="required"
                  >
                    <ImagePreview
                      v-model="image"
                      :label="$t('labels.image')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-col>
                <b-col cols="12" />
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12">
                  <div class="d-flex align-items-center justify-content-between">
                    <b-card-text class="mb-0">
                      {{ $t('labels.status') }}
                    </b-card-text>
                    <b-form-checkbox
                      v-model="form.status"
                      checked="true"
                      value="active"
                      unchecked-value="inactive"
                      name="check-button"
                      switch
                    />
                  </div>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12 text-center">
                  <LoadingButton />
                  <b-button
                    type="reset"
                    variant="outline-secondary"
                  >
                    {{ $t('labels.reset') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import _ from 'lodash'
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      isDataLoaded:false,
      image: null,
      countries: [],
      snowOption: {
        theme: 'snow',
      },
      form: {
        name: {
          en: null,
          ar: null,
        },
        details: {
          en: null,
          ar: null,
        },
        country_id: null,
        image: null,
        status: 'active',
      },
    }
  },
  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
  },
  created() {
    this.getCountries()
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    getCountries() {
      this.axios.get('/lists/Country')
        .then(res => {
          this.countries = res.data
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = _.pick(data, [
            'name',
            'details',
            'country_id',
            'image',
            'status',
          ])
          this.form = {
            ...this.form,
          }
          this.image = data.image
        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        });
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (this.isEdit) {
        if (!($form.image instanceof File)) {
          delete $form.image
        }
        // eslint-disable-next-line no-unused-vars
        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },
  },
}
</script>
